import React, {useContext, useRef,useEffect, useState } from 'react';
import {Button, Form, Input, message, Row, Col} from 'antd';
import {updateWord} from '../../../api/api';
import {StateContext} from '../../../store/contexts';

const {TextArea} = Input
interface BookFormProps {
    type: string;
}
const WordExamplesForm: React.FC<BookFormProps> = ({type}) => {
    const {state, dispatch} = useContext(StateContext);
    const {word: {currentWord}} = state;
    const [examplesData, setExamplesData] = useState([]);
    const ref = useRef(null);
  
    const validateMessages = {
        required: "${label} is required!",
    };
    const exampleInput = (e: any) => {
        const examples = e.target.value.split('\n').filter((item: any) => item);
        setExamplesData(examples);
    }

    const formConfirm = (values: any) => {
        let newWord = {
            _id: currentWord._id,
            examples: [...currentWord.examples, ...examplesData]
        };
        updateWord(newWord).then((res: any) => {
            if(res.code === 0) {
                message.success('update success')
                dispatch({type: "MODAL_VISIBLE", payload: false});
                dispatch({type: "WORD_REFRESH", payload: + new Date()});
            } else {
                
            }
        })
    }

    return (
        <>
            <Form labelCol={{ span: 3 }}
        wrapperCol={{ span: 21 }} ref={ref} onFinish={formConfirm} validateMessages={validateMessages}>
                <Form.Item name={'examples'} initialValue={type === 'edit' ? (currentWord['examples'] && currentWord['examples'].join('\n')) : currentWord['examples']} label="例句" rules={[{ required: false }]}>
                    <TextArea size='large' style={{height: '100px'}} onChange={exampleInput} />
                </Form.Item>
                <Form.Item labelCol={{ span: 21 }} wrapperCol={{ span: 3 }} label=" " >
                    <Button  size='large' type="primary" htmlType="submit">
                        {type === 'edit' ? '确认修改' : '添加'}
                    </Button>
                </Form.Item>
            </Form>
        </>
    );
};

export default WordExamplesForm;