import React, { Suspense,useReducer } from 'react';
import "./App.css";
import {
  Routes,
  Route,
  Link
} from "react-router-dom";
import Book from './pages/book/index';
import Project from './pages/project/index'
import Dashboard from './pages/dashboard/index';
import {StateContext} from './store/contexts';
import reducer from './store/reducer'
import {initState} from './store/state';
import HomePage from './pages/homePage';

function App() {
  const [state, dispatch] = useReducer(reducer, initState);
  return (
    <div className="app h-full dark">
      <StateContext.Provider value={{state, dispatch}}>
        <Routes>
            <Route path="/home" element={<HomePage />} />
            <Route path="/" element={<Book />} />
            <Route path="/:book" element={<Book />} />
            <Route path="/:book/:chapter" element={<Book />} />
        </Routes>
      </StateContext.Provider>
    </div>
    
  );
}

export default App;