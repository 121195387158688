import {FundOutlined, CarryOutOutlined } from '@ant-design/icons';
export const bookMenu =  [
   {
    icon: FundOutlined,
    label: '全局看板',
    key: 'dashboard'
   },
   {
    icon: CarryOutOutlined,
    label: '背词计划',
    key: 'project'
   }
]

export const oldBookMenu =  [
   {
    icon: FundOutlined,
    label: '新增',
    key: '/old/add'
   },
   {
    icon: CarryOutOutlined,
    label: '全部书本',
    key: '/old'
   }
]