import React, { useState,useEffect, useContext} from 'react';
import {getChapters, deleteChapter} from '../../api/api';
import { Link } from 'react-router-dom';
import {StateContext} from '../../store/contexts';
import {PlusCircleOutlined, EditOutlined, DeleteOutlined} from '@ant-design/icons'
import {Popconfirm, message, Spin, Skeleton, Empty} from 'antd'

const Chapter:React.FC<{}> = () => {
    const [chapterData, setChapterData] = useState([]);
    const {state, dispatch} = useContext(StateContext);
    const [currentIndex, setCurrentIndex] = useState(999);
    const {
        book: {currentBook: {title, _id, key: bookKey}, bookLoading}, 
        chapter: {chapterRefresh, chapterLoading, currentChapter, chapterPath, chapterList},
        config: {isInit}
    } = state;
    // 获取章节数据
    useEffect(() => {
        console.log('--', _id, chapterRefresh);
        if(_id) {
            dispatch({type: 'CHAPTER_LOADING', payload: true})
            getChapters({book_id: _id}).then((res:any) => {
                setChapterData(res.result)
                dispatch({type: 'CHAPTER_LOADING', payload: false})
                dispatch({type:'CHAPTER_LIST', payload: res.result});
                dispatch({type: 'CHAPTER_REFRESH', payload: false})
            }).catch(err => {
                dispatch({type: 'CHAPTER_LOADING', payload: false})
                dispatch({type:'CURRENT_CHAPTER', payload:{}})
            })
        }
    }, [_id, chapterRefresh]);
    
    useEffect(() => {
        if (!chapterPath && chapterList.length){
            dispatch({type:'CURRENT_CHAPTER', payload:chapterList[0]})
            dispatch({type:'CURRENT_WORD', payload:{}})
        } else {
            console.log(chapterList, chapterPath);
            let data = chapterList.filter((item:any) => item.key === chapterPath)
            dispatch({type:'CURRENT_CHAPTER', payload: data.length ? data[0] : {key:'', icon: '', name: ''}})
            dispatch({type:'CURRENT_WORD', payload: {}})
        }
    }, [chapterPath, chapterList])

    // 点击新增课本
    const addChapterEvent = () =>{
        dispatch({type: 'MODAL_TYPE', payload:'addChapter'})
        dispatch({type: 'MODAL_VISIBLE', payload:true})
    }

    // 点击编辑章节
    const editChapterEvent = (e: React.MouseEvent<HTMLElement>,item: any) => {
        /*阻止冒泡Start*/
        e.preventDefault()
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
        /*阻止冒泡End*/

        console.log('item', item);
        dispatch({type:'MODAL_VISIBLE', payload: true});
        dispatch({type: 'CURRENT_OPERATIONAL_CHAPTER', payload: item});
        dispatch({type:'MODAL_TYPE', payload: 'editChapter'})
        
    }

    // 删除章节
    const deleteChapterEvent = (id:string) => {
        deleteChapter({_id:id}).then((res: any) => {
            if(res.code === 0) {
                message.success('删除成功');
                dispatch({type: 'CHAPTER_REFRESH', payload: + new Date()});
            }
        })
    }
    const chapterClick = (id: String) => {
        if(id === currentChapter._id) {
            return;
        }
        dispatch({type:'WORD_LOADING', payload: true})
    }
    const chapters = () => {
        const list = chapterData.map((item: any, index:any) => {
            return  <Link  key={item._id} 
            className={`mb-1 py-2 px-5 block h-full w-full transition-none hover:bg-slate-400 hover:text-white ${currentChapter.key === item.key ? 'bg-slate-400 text-white': 'text-black'}` }
                    to={`/${bookKey}/${item.key}`} >
                    <div className='flex'  
                   
                    onMouseLeave={() =>{setCurrentIndex(999)}} 
                    onMouseEnter={() => {setCurrentIndex(index)}} 
                    onClick={() => {chapterClick(item._id)}}
                    >
                        <span className="flex-1">{item.name}</span>
                        {
                            currentIndex === index ? <p className="relative cursor-pointer">
                            <span className='mr-4' onClick={(e) => {editChapterEvent(e, item)}}><EditOutlined /></span>
                            <span>
                            <Popconfirm
                                title="确定要删除该章节么?"
                                onConfirm={(e:any) => {e.stopPropagation(); deleteChapterEvent(item._id)}}
                                onCancel={() =>{}}
                                okText="Yes"
                                cancelText="No"
                            >
                                 <DeleteOutlined onClick={(e) => {e.stopPropagation()}} />
                            </Popconfirm>
                            </span>
                        </p>
                        : null
                        }
                </div>
                </Link>
        })
        return <div className=''> {chapterData.length ?list : <Empty description="暂无章节" image={Empty.PRESENTED_IMAGE_SIMPLE} />} </div>
    }

    return (
        <div className="border-l-[1px] border-r-[1px] w-[180px]">
           <div className='top-bar flex items-center px-4'>
            <div className='h-8 flex-1 font-bold'>{title}</div>
            <div className='h-8' onClick={addChapterEvent}><PlusCircleOutlined className="cursor-pointer" /></div>
           </div>
           {(chapterLoading) ? <Skeleton style={{width: '80%', margin:'10px auto'}} active /> : 
           chapters()
           }
           <Spin spinning={chapterRefresh} className="pl-16"></Spin>
        </div>
    );
};

export default Chapter;