
import React, { useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import {deleteBook, getBooks } from '../../api';
import {bookMenu} from '../../constants/book-menu';
import {BookOutlined, EditOutlined, DeleteOutlined} from '@ant-design/icons';
import { Button, message, Popconfirm, Spin, Skeleton} from 'antd';
import {StateContext} from '../../store/contexts';
export interface BookProps {
    _id: string;
    title: string;
    key: string;
    icon: string;
}

interface BookItemProps {
    _id: string;
    icon: string;
    key: string;
    title: string;
}

const Menu: React.FC<{}> = () => {
    const [bookData, setBookData] = useState([]);
    const {state:{book:{currentBook, bookRefresh, bookLoading, bookPath, bookList}, config:{isInit}}, dispatch} = useContext(StateContext);
    const [currentIndex, setCurrentIndex] = useState(999);
    // 请求课本数据
    useEffect(() => {
        dispatch({type: 'BOOK_LOADING', payload: true})
        getBooks({}).then((res: any) => {
            setBookData(res.result);
            dispatch({type: 'BOOK_LOADING', payload: false})
            dispatch({type: 'BOOK_LIST', payload: res.result})
        })
    }, [bookRefresh]);
    
    useEffect(() => {
        console.log(bookPath, bookList);
        if (!bookPath && bookList.length){
            dispatch({type:'CURRENT_BOOK', payload: bookList[0]})
        } else{
            console.log(bookList, bookPath);
            let data = bookList.filter((item:any) => item.key === bookPath)
            dispatch({type:'CURRENT_BOOK', payload: data.length ? data[0]: {icon:'', title: '', key: ''}})
        }
    }, [bookPath, bookList])
      
    // 点击menu，store写入当前课本
    const setCurrentBook=(item: any) => {
        dispatch({type: 'CURRENT_BOOK', payload: item})
        dispatch({type: 'CURRENT_CHAPTER', payload: {}})
        dispatch({type: 'CHAPTER_LOADING', payload: true})
        dispatch({type: 'WORD_LOADING', payload: true})
    }
    // 点击新增课本
    const addBookEvent = () =>{
        dispatch({type: 'MODAL_TYPE', payload:'addBook'})
        dispatch({type: 'MODAL_VISIBLE', payload:true})
        dispatch({type: 'CURRENT_OPERATIONAL_BOOK', payload: {key: null}})
    }

    // 点击编辑课本
    const editBookEvent = (e: React.MouseEvent<HTMLElement>,item: any) => {
        /*阻止冒泡Start*/
        e.preventDefault()
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
        /*阻止冒泡End*/
        console.log('item', item);
        dispatch({type: 'CURRENT_OPERATIONAL_BOOK', payload: item});
        dispatch({type:'MODAL_TYPE', payload: 'editBook'})
        dispatch({type:'MODAL_VISIBLE', payload: true});
    }

    // 点击删除课本
    const deleteBookEvent = (id:string) => {
        deleteBook({_id:id}).then((res: any) => {
            if(res.code === 0) {
                message.success('删除成功');
                dispatch({type: 'BOOK_REFRESH', payload: + new Date()});
            }
        })
    }

    // 总菜单
    const menus = () => {
        const list = bookMenu.map((item: any,index) => {
            return <h3 key={index} className="flex items-center px-5 py-2 hover:bg-gray-200">
              {React.createElement(item.icon)} <Link to={`/${item.key}`} className="ml-2 w-full h-full">{item.label}</Link>
            </h3>
        })
        return <div className='mb-4'>{list}</div>
    }

    // 所有课本
    const books = () => {
        console.log(1111);
        const list = bookData.map((item: BookItemProps, index) => {
            return <Link key={item._id}  to={`/${item.key}`} className={`block relative h-full w-full hover:bg-slate-400 hover:text-white  transition-none ${currentBook.key === item.key ? 'text-white bg-slate-400' : 'text-black'}`}>
                <li className={`mb-2 relative pl-12 py-1` } 
                    onMouseLeave={() =>{setCurrentIndex(999)}} 
                    onMouseEnter={() => {setCurrentIndex(index)}} 
                    onClick={() => {setCurrentBook(item);}}>
                    <div className="h-full w-full flex items-center pr-4">
                        <span className='flex-1 text-inherit'>{item.title}</span>
                        {index === currentIndex ? 
                            <p className="absolute z-10 m-auto right-2 top-0 bottom-0 cursor-pointer">
                                <span className='mr-4' onClick={(e) => {editBookEvent(e, item)}}><EditOutlined /></span>
                                <span>
                                <Popconfirm
                                    title="确定要删除该课本么?"
                                    onConfirm={(e:any) => {e.stopPropagation(); deleteBookEvent(item._id)}}
                                    onCancel={() =>{}}
                                    okText="Yes"
                                    cancelText="No"
                                >
                                     <DeleteOutlined onClick={(e) => {e.stopPropagation()}} />
                                </Popconfirm>
                                </span>
                            </p>
                        : null}
                    </div>
                </li>
                </Link>
        })
       return <div>
            <div className="flex items-center mb-2 px-5 py-2 hover:bg-gray-200">
                <BookOutlined/>
                <h3 className="ml-2">全部课本</h3>
            </div>
            <ul className=''>
                {list}
            </ul>
       </div>
    }

    // 新增课本按钮
    const addBookButton = () => {
        return <div className='mt-5 mb-5 p-4'>
            <Button className='w-full' onClick={() => {addBookEvent()}}> +  新增课本</Button>
        </div>
    }
    return (
       <div className='w-[200px] bg-slate-100'>
        <div className='top-bar'></div>
        {addBookButton()}
        {menus()}
        {bookLoading && isInit ? <Skeleton style={{width: '80%', margin:'auto'}} active /> : books()}
        <Spin spinning={bookLoading} className="pl-16"></Spin>
       </div>
    )
}

export default Menu;
