export const initState: any = {
    book:{
        currentBook: {
            title: '',
            icon: '',
            key: '',
            _id: ''
        },
        currentOperationalBook: {
            title: '',
            icon: '',
            key: ''
        },
        bookRefresh: false,
        bookLoading: true,
        bookPath: '',
        bookList: []
    },
    chapter: {
        currentChapter: {
            name: '',
            icon: '',
            key: '',
            _id: '',
            source: ''
        },
        currentOperationalChapter: {
            name: '',
            icon: '',
            key: '',
            _id: '',
            source: ''
        },
        chapterRefresh: false,
        chapterLoading: false,
        chapterPath: '',
        chapterList: []
    },
    modal: {
        name: '',
        type: 'default',
        visible: false
    },
    word: {
        wordLoading: false,
        wordRefresh: false,
        currentWord: {
            word: '',
            exam_type : [],
            explains: [],
            phonetic: '',
            examples: [],
            _id: ''
        },
        currentWordList: [],
        filterWordList: [],
        mode: 'view', //
        status: 'all' // Incognizant fuzzy Cognition
    },
    user: {
        user_id: ''
    },
    config: {
        isInit: true
    },
    wordTools:{
        'word-status': -1
    }
}