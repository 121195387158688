import React from 'react'
import './index.css'
const HomePage: React.FC<{}> = () => {
    return <div className='home-main'>
        <div className='h-[40px] flex items-center'>
            <div className='w-[80%] m-auto flex'>
                <div className='flex-1 font-bold text-white text-[18px]'>V</div>
                <ul className='flex text-white items-center justify-center'>
                    <li>登录</li>
                    <li className='ml-2'>注册</li>
                </ul>
            </div>
        </div>
        <div>
            <div className=' text-[80px] text-gray-100 h-[calc(100vh-80px)] flex items-center justify-center '>
              
            </div>
        </div>
        <div className='h-[40px] text-gray-200 flex items-center justify-center font-[18px]'>Have a good study time!</div>
    </div>
}

export default HomePage