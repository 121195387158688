import $ from 'jquery';
import CryptoJS from 'crypto-js'
export const  truncate = (q: string) => {
    var len = q.length;
    if(len<=20) return q;
    return q.substring(0, 10) + len + q.substring(len-10, len);
}

export const searchWord = (query: string) => {
    const appKey = '5059ff8591ad8e75';
    const key = 'KvwBZiwyVgUg4RtNt2GAgZdTkt9lKMii';//注意：暴露appSecret，有被盗用造成损失的风险
    const salt = (new Date).getTime();
    const curtime = Math.round(new Date().getTime()/1000);
    const from = 'en';
    const to = 'zh-CHS';
    const str1 = appKey + truncate(query) + salt + curtime + key;
    const vocabId =  '';
    var sign = CryptoJS.SHA256(str1).toString(CryptoJS.enc.Hex);

    return new Promise((resolve, reject) => {
        $.ajax({
            //url: 'https://openapi.youdao.com/api',
            url: 'https://openapi.youdao.com/v2/dict',
            type: 'post',
            dataType: 'jsonp',
            data: {
                q: query,
                appKey: appKey,
                salt: salt,
                from: from,
                to: to,
                sign: sign,
                signType: "v3",
                curtime: curtime,
                vocabId: vocabId,
            },
            success: function (data) {
                resolve(data)
            } 
        });
    })
    
}





