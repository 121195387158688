import React, { useState,useEffect, useRef, useContext } from 'react';
import { Modal, Button, Form, Input } from 'antd';
import {StateContext} from '../../../store/contexts';
import BookForm from './BookForm';
import ChapterForm from './ChapterForm'
import WordForm from './WordForm';
import MultiWordsForm from './MultiWordsForm'
import WordExamplesForm from './WordExamplesForm';
interface FormModalProps {
    visible: boolean;
}

const formType: any = {
    default: {title: '', component: null},
    addBook: {title: '新增课本', component: <BookForm type='add'/>},
    editBook: {title: '编辑课本', component: <BookForm type='edit'/>},
    addChapter: {title:'新增章节', component: <ChapterForm type='add' />},
    editChapter: {title: '编辑章节', component: <ChapterForm type='edit' />},
    addSingleWord: {title: '新增单词', component: <WordForm type='add' />},
    addMultiWords: {title: '批量添加单词', component: <MultiWordsForm />},
    editWord: {title: '编辑单词', component: <WordForm type='edit' />},
    newExamples: {title: '新增例句', component: <WordExamplesForm type='add' />},

}
const FormModal: React.FC<FormModalProps> = ({visible}) => {
    const {state, dispatch} = useContext(StateContext);
    const {type} = state.modal;
    
    const handleCancel =() =>{
        dispatch({type: 'MODAL_VISIBLE', payload:false})
    }
    
    const renderFormByType = (type: string) => {
        if (type) {
            return formType[type].component;
        }
    }
    return (
        <>
            <Modal title={formType[type].title} visible={visible}  onCancel={handleCancel} footer={null}>
                {renderFormByType(type)}
            </Modal>
        </>
    );
};

export default FormModal;