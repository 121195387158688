export const createPromise = (fn: any, parms: any) =>  {
    const promise = new Promise((resolve, reject) => {
        fn(parms).then((res: any) => {
            resolve(res);
        }).catch((err: any)=> {
            if(typeof parms === 'string') {
                resolve({word: parms});
            } else{
                return {}
            }
            
        })
    })
    return promise;
}