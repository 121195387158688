
import React, { useEffect, useState, useContext } from 'react';
import {toolsSort, toolsMap} from '../../../constants/word-tools'
import { message, Tooltip} from 'antd'
import {StateContext} from '../../../store/contexts';
const classNames = require('classnames');

const WordTools: React.FC<{}> = () => {
    const {state, dispatch} = useContext(StateContext);
    const {
        chapter: {currentChapter},
        word: {currentWordList},
        wordTools,
    } = state;
    
    useEffect(() => {

    }, wordTools)
    
    const eventMap = {

        // 添加单个单词
        addSingleWordEvent: () => {
            if (!currentChapter._id) {
                message.error('请选择章节')
            } else {
                dispatch({type: 'CURRENT_WORD', payload:{}})
                dispatch({type: 'MODAL_VISIBLE', payload:true})
                dispatch({type: 'MODAL_TYPE', payload:'addSingleWord'})
            }
        },

        // 批量添加单词
        addMultiWordsEvent: () => {
            if (!currentChapter._id) {
                message.error('请选择章节')
            } else { 
                dispatch({type: 'MODAL_VISIBLE', payload:true})
                dispatch({type: 'MODAL_TYPE', payload:'addMultiWords'})
            }
        },

        // 根据状态筛选单词
        statusClickEvent: (key:string, status: number) => {
           if (status < 0) {
            dispatch({type: 'FILTER_WORD_LIST', payload: currentWordList});
           } else {
            const list =  currentWordList.filter((item: any)=> {
                return item.status === status;
           })
            dispatch({type: 'FILTER_WORD_LIST', payload: list});
           }
           
           const newwWordTools = JSON.parse(JSON.stringify(wordTools));
           newwWordTools[key] = status;
           dispatch({type:"WORDS_TOOLS", payload: newwWordTools})
        },

        // 单词展示状态
        layoutClickEvent: (item:any, itemkey:any) => {
            console.log(item, itemkey);
        }
    }
    const tools = () => {
        return toolsSort.map((item:any, index:any) => {
            const list = (toolsMap as any)[item].list.map((listItem: {color:string; label:string; event:string; icon:string; active: boolean,key:any}, index:any) => {
                const {color, icon} = listItem;
                return <Tooltip title={listItem.label} color="cyan" key={listItem.key}>
                    <li className={`px-4  cursor-pointer`} >
                        <i className={classNames('iconfont','rounded-full', 'text-[24px]' , icon, color, listItem.key == wordTools[item] ? 'border-[1px]': 'border-[1px] border-gray-300')} 
                        onClick={() => {(eventMap as any)[listItem.event](item, listItem.key)}}>
                        </i>
                    </li>
                    </Tooltip>
            })
            return <ul className='tools-item relative flex justify-between px-4 items-center flex-1' key={item}>{list}</ul>
        })
    }
    return (
       <div className='flex'>
        {tools()}
       </div>
    )
}

export default WordTools
