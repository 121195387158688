import React, {useContext, useRef,useEffect, useState } from 'react';
import {Button, Form, Input, message, Row, Col} from 'antd';
import {addWord, updateWord} from '../../../api/api';
import {StateContext} from '../../../store/contexts';
import {searchWord} from '../../../utils/searchWord';

const {TextArea} = Input
interface BookFormProps {
    type: string;
}
const WordForm: React.FC<BookFormProps> = ({type}) => {
    const {state, dispatch} = useContext(StateContext);
    const {book:{currentBook}, chapter:{currentChapter}, word: {currentWord}} = state;
    const [inputWord, setWord] = useState('');
    const [examplesData, setExamplesData] = useState([]);
    const ref = useRef(null);
  
    const validateMessages = {
        required: "${label} is required!",
    };

    const searchWordEvent = async () => {
        const wordData:any = await searchWord(inputWord);
        const {basic: {
            exam_type,
            explains,
            'uk-phonetic':phonetic
            }} = wordData;
            dispatch({type: "CURRENT_WORD", payload: {
                word: inputWord,
                explains,
                phonetic,
                exam_type,
                examples: []
            }})
    }

    const exampleInput = (e: any) => {
        const examples = e.target.value.split('\n').filter((item: any) => item);
        setExamplesData(examples);
    }

    const formConfirm = (values: any) => {
        let newWord = {
            book_id: currentBook._id,
            chapter_id: currentChapter._id,
            ...values,
            examples: examplesData
        };
        if (type === 'add') {
            addWord(newWord).then((res:any) => {
                if(res.code === 0) {
                    message.success('add success');
                    dispatch({type: "MODAL_VISIBLE", payload:false})
                    dispatch({type: "CURRENT_WORD", payload: res.result})
                    dispatch({type: "WORD_REFRESH", payload: + new Date()});
                } else {
                    
                }
            })
        } else {
            newWord = {...newWord, ...{_id: currentWord._id, date: currentWord.date}}
            updateWord(newWord).then((res: any) => {
                if(res.code === 0) {
                    message.success('update success')
                    console.log('---', res)
                    dispatch({type: "MODAL_VISIBLE", payload: false});
                    dispatch({type: "CURRENT_WORD", payload: newWord})
                    dispatch({type: "WORD_REFRESH", payload: + new Date()});
                } else {
                    
                }
            })
        }
       
    }
    useEffect(() => {
       (ref.current as any).resetFields(); // 必须加 不然切换编辑 表单不更新
    }, [type, currentWord.word])

    return (
        <>
            <Form labelCol={{ span: 3 }}
        wrapperCol={{ span: 15 }} ref={ref} onFinish={formConfirm} validateMessages={validateMessages}>
                <Form.Item>
                <Row gutter={15}>
                    <Col span={20}>
                        <Form.Item name={'word'} initialValue={currentWord['word']} label="单词" rules={[{ required: true }]}>
                        <Input onChange={(e:any) => {setWord(e.target.value)}} />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Button onClick={searchWordEvent}>搜索</Button>
                    </Col>
                    </Row>
                </Form.Item>
                <Form.Item name={'phonetic'} initialValue={currentWord['phonetic']} label="音标" rules={[{ required: true }]}>
                    <Input />
                </Form.Item>
                <Form.Item name={'explains'} initialValue={currentWord['explains']} label="解释" rules={[{ required: true }]}>
                    <Input />
                </Form.Item>
                <Form.Item name={'examples'} initialValue={type === 'edit' ? (currentWord['examples'] && currentWord['examples'].join('\n')) : currentWord['examples']} label="例句" rules={[{ required: false }]}>
                    <TextArea size='large' style={{height: '100px'}} onChange={exampleInput} />
                </Form.Item>
                <Form.Item name={'exam_type'} initialValue={currentWord['exam_type']} label="考试类型" rules={[{ required: false }]}>
                    <Input  />
                </Form.Item>
                <Form.Item>
                    <Button size='large' type="primary" htmlType="submit">
                        {type === 'edit' ? '确认修改' : '添加'}
                    </Button>
                </Form.Item>
            </Form>
        </>
    );
};

export default WordForm;