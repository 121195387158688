/******************
 * 
 * @file 批量新增单词表单
 * @author freyhill
 * @date last update on 2022-09-27
 * 
 *****************/

import React, {useContext, useEffect, useState } from 'react';
import {Button, Input} from 'antd';
import {addMutiWords} from '../../../api/api';
import {StateContext} from '../../../store/contexts';
import {searchWord} from '../../../utils/searchWord';
import {createPromise} from '../../../utils/utils';

const { TextArea } = Input;
const WordForm: React.FC<{}> = () => {
    const {state, dispatch} = useContext(StateContext);
    const {book:{currentBook}, chapter:{currentChapter}} = state;
    const [inputWords, setWords] = useState([]);
    const [searchNum, setSearchNum] = useState(0);
    const [isInserting, setIsInserting] = useState(false);
    const [failedWords, setFailedWords] = useState([]);
    const [requestWordsList, setRequestWordList] = useState([]);
    // 搜索单词请求
    const searchWordEvent = async (word: string) => {
        try{
            const wordData: any = await searchWord(word);
            const {basic} = wordData;
            return {
                exam_type: basic.exam_type || [],
                explains: basic.explains || [],
                phonetic: basic.phonetic || '', 
                word: word
            }
        }catch(err) {
            console.log('word', word);
            //setFailedWords(newFailedWords);
            (setFailedWords as any)((failedWords:any) => [...failedWords, word]);
        }
    }

    // 批量搜索单词并处理
    const searchWords = async () => {
        const wordsArr = (inputWords as any).split(/[(\r\n\s)\r\n\s]+|,+/).filter((item: any) => item);
        setSearchNum(wordsArr.length);
        setFailedWords([]);
        setRequestWordList([]);
        let list:any = [];
        wordsArr.map((item:any) => list.push(createPromise(searchWordEvent, item)))
        Promise.all(list).then(res => {
            (setRequestWordList as any)(res);
        })
    }

    // 批量插入请求
    const addWrods = (words:any) => {
        console.log('words', words)
        const wordList = words.map((item:any) => {
            if(item) {
                item.book_id = currentBook._id
                item.chapter_id = currentChapter._id;
                item.date = new Date().toISOString().split('T')[0];
                return item;
            }
        })
        setIsInserting(true)
        console.log('failedWords---', failedWords)
        addMutiWords({words: wordList}).then((res:any) => {
            if(res.code === 0) {
                setIsInserting(false)
                dispatch({type: "WORD_REFRESH", payload: + new Date()});
                if(failedWords.length) {
                    setWords((failedWords as any).join(" "));
                } else {
                   // dispatch({type: "MODAL_VISIBLE", payload: false});
                }
            } else {

            }
        })
    }
    useEffect(() => {
        addWrods(requestWordsList);
    }, [requestWordsList.length])

    return (
        <>
           <TextArea 
           style={{width:'100%', minHeight:"200px"}} 
           placeholder='请粘贴需要批量插入的单词'
           onChange={(e:any) => {
            setWords(e.target.value)
           }}
           value={inputWords}
           />
            <div>共{searchNum}个单词</div>
            {
                failedWords.length ? <div>以下{failedWords.length}单词添加失败：{failedWords.join(" ")}</div> : null
            }
            { isInserting ?  <Button disabled>正在插入</Button> : 
                <Button  onClick={searchWords}>
                    {failedWords.length ? '继续插入添加失败的单词' :'开始插入单词'}
                </Button>
            }
        </>
    );
};

export default WordForm;