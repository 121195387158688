
import React, { useEffect, useState, useContext } from 'react';
import {useLocation} from 'react-router-dom'
import Menu from './Menu';
import Chapter from './Chapter'
import Content from './Content'
import Aside from './Aside';
import FormModal from './components/FormModal';
import {StateContext} from '../../store/contexts';

const Book: React.FC<{}> = () => {
    let {pathname} = useLocation();
    const {state, dispatch} = useContext(StateContext);
    useEffect(() => {
      
    }, [])
    useEffect(() => {
        const pathArr = pathname.split('/').filter(item => item);
        dispatch({type: 'BOOK_PATH', payload: pathArr[0]})
        dispatch({type: 'CHAPTER_PATH', payload: pathArr[1]})
        dispatch({type: 'USER_INFO', payload: {user_id: '62501e57cb5046b09be7de62'}});
        setTimeout(() => {
            console.log(state);
        }, 1000)
        
    }, [pathname])
  
    return (
       <div className="flex h-full">
        <Menu/>
        <Chapter/>
        <Content/>
        <Aside/>
        <FormModal visible={state.modal.visible}/>
       </div>
    )
}

export default Book;
