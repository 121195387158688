// words api
export const wordsApi = {
    // book related
    bookApi: '/api/book', 
    
    // charpter api
    chapterApi: '/api/chapter',
    
    // word api
    wordApi: '/api/word',
    // category related
    addCategoryApi: '/api/wordstore/add_category', 
    getCategoryApi: '/api/wordstore/get_category',
    updateCategoryApi: '/api/wordstore/update_category',
    deleteCategoryApi: '/api/wordstore/delete_category',

    addUserApi:  '/api/wordstore/add_user',
    getMenuApi:'/api/wordstore/get_menu',

    // words related
    addWordApi: '/api/wordstore/add_word',
    getWordsApi:'/api/wordstore/get_words',
    updateWordApi:'/api/wordstore/update_word',
    deleteWordApi:'/api/wordstore/delete_word',
}